<template>
  <main class="main courses-page">
    <section class="container courses-page__container">
      <div class="courses-page__header">
        <h1 class="courses-page__title">Все курсы</h1>
        <span class="courses-page__count">{{ $store.state.courses_page.courses_count }}</span>
        <button class="btn btn--gray-outline btn--small courses-page__settings-btn" @click="showFilterModal">
          <SettingsIcon />
        </button>
      </div>
      <div class="courses-page__content">
        <CoursesFilterComponent
          @change="filter"
          :loading="filterLoading"
          :categories="categories"
          :types="types"
          :price_range="priceRange"
        />
        <div class="courses-page__list">
          <template v-if="showFavoriteCoursesBlock">
            <div class="courses-page__list-header">
              <h2 class="courses-page__list-title">Избранные курсы и лекции</h2>
              <button class="btn btn--gray-outline btn--small" @click="showAll(type.FAVORITE)">
                <LoadingIndicator title="Загрузка" v-if="favoriteSettings.loading" />
                <template v-else>
                  <template v-if="favoriteSettings.showAll">Свернуть</template>
                  <template v-else>Показать все</template>
                </template>
              </button>
            </div>
            <div class="courses-list" v-if="showListEmpty">Список пуст</div>
            <div
              class="courses-list"
              :class="{
                'courses-list--active': favoriteSettings.showAll,
              }"
            >
              <CourseComponent v-for="(course, i) in favorite_courses.data" :key="i" :course="course" />
            </div>
            <button
              class="courses-page__mobile-show-all btn btn--gray-outline btn--small"
              @click="showAll(type.FAVORITE)"
            >
              <LoadingIndicator title="Загрузка" v-if="favoriteSettings.loading" />
              <template v-else>
                <template v-if="favoriteSettings.showAll">Свернуть</template>
                <template v-else>Показать все</template>
              </template>
            </button>
          </template>
          <PaginationComponent
            v-if="showFavoriteCoursesPagination"
            :page="favoriteSettings.page"
            :total="Math.ceil(favorite_courses.paginatorInfo.total / favoriteSettings.first)"
            @change="paginate($event, type.FAVORITE)"
            @more="paginate($event, type.FAVORITE, true)"
          />
          <template v-if="showPopularCoursesBlock">
            <div class="courses-page__list-header">
              <h2 class="courses-page__list-title">Популярные курсы и лекции</h2>
              <button class="btn btn--gray-outline btn--small" @click="showAll(type.POPULAR)">
                <LoadingIndicator title="Загрузка" v-if="popularSettings.loading" />
                <template v-else>
                  <template v-if="popularSettings.showAll">Свернуть</template>
                  <template v-else>Показать все</template>
                </template>
              </button>
            </div>
            <div
              class="courses-list"
              :class="{
                'courses-list--active': popularSettings.showAll,
              }"
            >
              <CourseComponent v-for="(course, i) in popular_courses.data" :key="i" :course="course" />
            </div>
          </template>
          <PaginationComponent
            v-if="showPopularCoursesPagination"
            :page="popularSettings.page"
            :total="Math.ceil(popular_courses.paginatorInfo.total / popularSettings.first)"
            @change="paginate($event, type.POPULAR)"
            @more="paginate($event, type.POPULAR, true)"
          />
          <template v-if="showTrendCoursesBlock">
            <div class="courses-page__list-header">
              <h2 class="courses-page__list-title">Трендовые курсы и лекции</h2>
              <button class="btn btn--gray-outline btn--small" @click="showAll(type.TREND)">
                <LoadingIndicator title="Загрузка" v-if="trendSettings.loading" />
                <template v-else>
                  <template v-if="trendSettings.showAll">Свернуть</template>
                  <template v-else>Показать все</template>
                </template>
              </button>
            </div>
            <div
              class="courses-list"
              :class="{
                'courses-list--active': trendSettings.showAll,
              }"
            >
              <CourseComponent v-for="(course, i) in trend_courses.data" :key="i" :course="course" />
            </div>
          </template>
          <PaginationComponent
            v-if="showTrendCoursesPagination"
            :page="trendSettings.page"
            :total="Math.ceil(trend_courses.paginatorInfo.total / trendSettings.first)"
            @change="paginate($event, type.TREND)"
            @more="paginate($event, type.TREND, true)"
          />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import CoursesFilterComponent from "../components/CoursesFilterComponent.vue";
import SettingsIcon from "@/components/svg/SettingsIcon.vue";
import PaginationComponent from "components/Pagination.vue";
import CourseComponent from "components/CourseComponent.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";

let desktopSettings = {
  favoriteFirst: 6,
  favoritePage: 1,
  popularFirst: 3,
  popularPage: 1,
  trendFirst: 6,
  trendPage: 1,
};
let mobileSettings = {
  favoriteFirst: 4,
  favoritePage: 1,
  popularFirst: 4,
  popularPage: 1,
  trendFirst: 4,
  trendPage: 1,
};
let _isMobile = false;

function settings() {
  return _isMobile ? mobileSettings : desktopSettings;
}
export default {
  name: "CoursesPage",
  async asyncData({ apollo, store, isMobile }) {
    _isMobile = isMobile;
    await store.dispatch("COURSES_PAGE", {
      apollo: apollo.defaultClient,
      loadingType: store.state._loading_types.DEFAULT,
      variables: settings(),
    });
  },
  data() {
    return {
      maxFirstWhenShowAll: 12,
      filterData: {
        categories: [],
        price_from: null,
        price_to: null,
        levels: [],
      },
      type: {
        FAVORITE: 0,
        POPULAR: 1,
        TREND: 2,
      },
      filterLoading: false,
      favoriteSettings: {
        showAll: false,
        loading: false,
        page: settings().favoritePage,
        first: settings().favoriteFirst,
      },
      popularSettings: {
        showAll: false,
        loading: false,
        page: settings().popularPage,
        first: settings().popularFirst,
      },
      trendSettings: {
        showAll: false,
        loading: false,
        page: settings().trendPage,
        first: settings().trendFirst,
      },
    };
  },
  computed: {
    showListEmpty() {
      return (
        !this.favorite_courses.data &&
        !this.favorite_courses.data.length &&
        !this.popular_courses.data &&
        !this.popular_courses.data.length &&
        !this.trend_courses.data &&
        !this.trend_courses.data.length
      );
    },
    showFavoriteCoursesBlock() {
      return (
        !this.trendSettings.showAll &&
        !this.popularSettings.showAll &&
        this.favorite_courses.data &&
        this.favorite_courses.data.length
      );
    },
    showFavoriteCoursesPagination() {
      return this.showFavoriteCoursesBlock && this.favoriteSettings.showAll && !this.favoriteSettings.loading;
    },
    showPopularCoursesBlock() {
      return (
        !this.trendSettings.showAll &&
        !this.favoriteSettings.showAll &&
        this.popular_courses.data &&
        this.popular_courses.data.length
      );
    },
    showPopularCoursesPagination() {
      return this.showPopularCoursesBlock && this.popularSettings.showAll && !this.popularSettings.loading;
    },
    showTrendCoursesBlock() {
      return (
        !this.favoriteSettings.showAll &&
        !this.popularSettings.showAll &&
        this.trend_courses.data &&
        this.trend_courses.data.length
      );
    },
    showTrendCoursesPagination() {
      return this.showTrendCoursesBlock && this.trendSettings.showAll && !this.trendSettings.loading;
    },
    categories() {
      return this.$store.state.courses_page.categories;
    },
    types() {
      return this.$store.state.courses_page.types;
    },
    priceRange() {
      return this.$store.state.courses_page.courses_min_max_price;
    },
    popular_courses() {
      return this.$store.state.courses_page.popular_courses;
    },
    favorite_courses() {
      return this.$store.state.courses_page.favorite_courses;
    },
    trend_courses() {
      return this.$store.state.courses_page.trend_courses;
    },
  },
  methods: {
    showFilterModal() {
      this.$store.state._modals.push({
        component: CoursesFilterComponent,
        options: {
          loading: this.filterLoading,
          categories: this.categories,
          types: this.types,
          price_range: this.priceRange,
          callback: this.filter,
        },
      });
    },
    async showAll(type) {
      if (!this.filterLoading) {
        switch (type) {
          case this.type.FAVORITE:
            if (!this.favoriteSettings.loading) {
              this.favoriteSettings.showAll = !this.favoriteSettings.showAll;
              this.favoriteSettings.loading = true;
              this.favoriteSettings.first = this.favoriteSettings.showAll
                ? this.maxFirstWhenShowAll
                : settings().favoriteFirst;
            }
            break;
          case this.type.POPULAR:
            if (!this.popularSettings.loading) {
              this.popularSettings.showAll = !this.popularSettings.showAll;
              this.popularSettings.loading = true;
              this.popularSettings.first = this.popularSettings.showAll
                ? this.maxFirstWhenShowAll
                : settings().popularFirst;
            }
            break;
          case this.type.TREND:
            if (!this.trendSettings.loading) {
              this.trendSettings.showAll = !this.trendSettings.showAll;
              this.trendSettings.loading = true;
              this.trendSettings.first = this.trendSettings.showAll
                ? this.maxFirstWhenShowAll
                : settings().trendFirst;
            }
            break;
        }
        await this.reloadData();
        this.favoriteSettings.loading = false;
        this.popularSettings.loading = false;
        this.trendSettings.loading = false;
      }
    },
    async filter(form) {
      if (!this.filterLoading) {
        this.filterLoading = true;
        this.filterData.categories = form.categories.value;
        this.filterData.price_from = form.price_from.value;
        this.filterData.price_to = form.price_to.value;
        this.filterData.levels = form.levels.value;
        await this.reloadData();
        this.filterLoading = false;
      }
    },
    async paginate(page, type, more) {
      if (!this.filterLoading) {
        switch (type) {
          case this.type.FAVORITE:
            if (!this.favoriteSettings.loading) {
              this.favoriteSettings.page = page;
              this.favoriteSettings.loading = true;
            }
            break;
          case this.type.POPULAR:
            if (!this.popularSettings.loading) {
              this.popularSettings.page = page;
              this.popularSettings.loading = true;
            }
            break;
          case this.type.TREND:
            if (!this.trendSettings.loading) {
              this.trendSettings.page = page;
              this.trendSettings.loading = true;
            }
            break;
        }
        await this.reloadData(more);
        this.favoriteSettings.loading = false;
        this.popularSettings.loading = false;
        this.trendSettings.loading = false;
      }
    },
    async reloadData(more) {
      window.scrollTo(0, 0);
      let loadingType = this.$store.state._loading_types.PAGINATION;
      if (more) {
        loadingType = this.$store.state._loading_types.LOAD_MORE;
      }
      await this.$store.dispatch("COURSES_PAGE", {
        apollo: this.$apollo,
        loadingType: loadingType,
        variables: {
          categories: this.filterData.categories,
          price_from: this.filterData.price_from,
          price_to: this.filterData.price_to,
          levels: this.filterData.levels,
          favoriteFirst: this.favoriteSettings.first,
          favoritePage: this.favoriteSettings.page,
          popularFirst: this.popularSettings.first,
          popularPage: this.popularSettings.page,
          trendFirst: this.trendSettings.first,
          trendPage: this.trendSettings.page,
        },
      });
    },
  },
  components: {
    LoadingIndicator,
    CourseComponent,
    PaginationComponent,
    CoursesFilterComponent,
    SettingsIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/courses.styl"
</style>
